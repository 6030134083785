const contractsHelper = {
    CalculatePercentPaid(contract) {
        let totalPaid = 0
        let paymentSum = 0
    
        if(contract.payments.length) {
            contract.payments.forEach(p => {
                paymentSum += parseFloat(p.amount)
            })
            
            totalPaid =  (paymentSum / parseFloat(contract.price)) * 100
        }
    
        contract.paymentSum = parseFloat(paymentSum)
        contract.percentPaid = parseFloat(totalPaid.toFixed(2))
    }
}

export default contractsHelper;