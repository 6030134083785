<template>
    <div class="p-fluid contract-form p-p-2">
        
        <div class="p-field" :class="{ 'form-group--error': $v.contract.schoolId.$error }">
            <label for="totalPrice">Škola</label>
            <SelectButton id="school" v-model="contract.schoolId" :options="schools" optionLabel="name" optionValue="id" :disabled="!edit" />
            <!-- <Dropdown id="school" v-model="contract.schoolId" :options="schools" optionLabel="name" optionValue="id" :disabled="!edit" /> -->
            <small class="p-invalid" v-if="!$v.contract.schoolId.required">Polje je obavezno</small>
        </div>
        
        <div class="p-field" :class="{ 'form-group--error': $v.contract.date.$error }">
            <label for="date">Datum</label>
            <Calendar v-model="contract.date" dateFormat="dd.mm.yy" :showButtonBar="true" :disabled="!edit" />
            <small class="p-invalid" v-if="!$v.contract.date.required">Polje je obavezno</small>
        </div>
        
        <div class="p-field" :class="{ 'form-group--error': $v.contract.price.$error }">
            <label for="price">Cijena</label>
            <InputNumber id="price" showButtons v-model="contract.price" mode="currency" currency="HRK" locale="hr-HR" :disabled="!edit" />
            <small class="p-invalid" v-if="!$v.contract.price.required">Polje je obavezno</small>
        </div>        

        <div class="p-field" :class="{ 'form-group--error': $v.contract.category.$error }">
            <label for="category">Kategorija</label>
            <InputText id="category" type="text" v-model.trim="contract.category" :disabled="!edit" />
            <small class="p-invalid" v-if="!$v.contract.category.required">Polje je obavezno</small>
        </div>

        <div class="p-field" :class="{ 'form-group--error': $v.contract.code.$error }">
            <label for="code">Šifra polaznika</label>
            <InputText id="code" type="text" v-model.trim="contract.code" :disabled="!edit" />
            <small class="p-invalid" v-if="!$v.contract.code.required">Polje je obavezno</small>
        </div>

        <div class="p-field" :class="{ 'form-group--error': $v.contract.name.$error }">
            <label for="name">Ime i prezime</label>
            <InputText id="name" type="text" v-model.trim="contract.name" :disabled="!edit" />
            <small class="p-invalid" v-if="!$v.contract.name.required">Polje je obavezno</small>
        </div>        

        <div class="p-field">
            <label for="telephone">Telefon</label>
            <InputText id="telephone" type="text" v-model.trim="contract.telephone" :disabled="!edit" />
        </div>
        
        <div class="p-field">
            <label for="note">Napomena</label>
            <Textarea id="note" v-model="contract.note" rows="4" :disabled="!edit" />
        </div>

        <!-- <div class="p-field-checkbox">
            <Checkbox id="binary" v-model="contract.warning" :value="1" :disabled="!edit" />
            <label for="binary">Obratiti pozornost</label>
        </div> -->
    </div>
</template>

<script>
import router from '@/router'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
export default {
    props: {
        contract: {
            type: Object,
            required: true,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            unchangedContract: {},
            hr: {
                firstDayOfWeek: 1,
                dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                dayNamesMin: ["Ne","Mo","Tu","We","Th","Fr","Sa"],
                monthNames: [ "Siječanj","Veljača","Ožujak","Travanj","Svibanj","Lipanj","Srpanj","Kolovoz","Rujan","Listopad","Studeni","Prosinac" ],
                monthNamesShort: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
                today: 'Danas',
                clear: 'Obriši',
                dateFormat: 'mm/dd/yy',
                weekHeader: 'Wk'
            },
            date: null
        }
    },    
    validations: {
        contract: {
            schoolId: { required },
            name: { required },
            code: { required },
            category: { required },
            date: { required },
            price: { required },
        } 
    },
    async created() {
        await this.getSchools()
    },
    computed: {
        ...mapGetters({
            schools: 'schools'
        })
    },
    methods: {
        async submit() {
            'id' in this.contract ? await this.update() : await this.save()
        },
        editContract() {
            this.unchangedContract = { ...this.contract }
            this.$emit('update:edit', true)
        },
        async save() {
            this.$v.$touch()
            if (this.$v.$invalid) return

            let contract = await this.addContract(this.contract)
            router.push({ path: `/contracts/${contract.id}` })
            // router.push({ path: '/contracts' })
        },
        async update() {
            this.$v.$touch()
            if (this.$v.$invalid) return

            this.$emit("update:contract", await this.updateContract(this.contract))
            this.$emit("update:edit", false)
        },
        async toggleWarning() {
            this.contract.warning = this.contract.warning === 1 ? 0 : 1; 
            this.$emit("update:contract", await this.updateContract(this.contract))
        },
        cancel() {
            this.$emit("update:contract", this.unchangedContract)
            this.$emit("update:edit", false)
        },
        back() {
            router.push({ path: '/contracts' })
        },
        ...mapActions({
            addContract: 'ADDCONTRACT',
            getSchools: 'GETSCHOOLS',
            updateContract: 'UPDATECONTRACT',
        }),
    },
}
</script>

<style>
    /* .p-inputtext {
        box-shadow: none;
        background:#f8f9fa;
        padding:10px;
    }
    .p-inputtext:read-only {
        background:#fff;
        cursor:default;
    }
    .p-inputtext:enabled:hover, .p-inputtext:enabled:focus {
        box-shadow: none;
    } */
    /* #yacht {
        font-size:26px;
        font-weight:900;
    } */
</style>