<template>
    <div class="contract--layout p-d-flex p-flex-column">
        <div class="actions p-d-flex p-jc-between p-p-2">
            <Button label="Natrag" icon="pi pi-arrow-left" class="p-button-text p-button-sm" @click="back()" />
            <Button label="Spremi" icon="pi pi-check" class="p-button-sm" @click="save()" />
        </div>

        <div class="contract--data p-d-flex">
            <div class="contract--form">                
                <Header>
                    Novi ugovor
                </Header>
                <Form :contract="contract" :edit="true" ref="form" />
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/common/Header'
import router from '@/router'
import { mapActions } from 'vuex'
import Form from './Form'
import contractsService from '../../services/contracts.service'
export default {
    data() {
        return {
            contract: {},
            loading: false
        }
    },
    components: {
        Header,
        Form
    },
    methods: {
        back() {
            router.push({ path: '/contracts' })
        },
        async save() {
            this.$refs.form.$v.$touch()
            if (this.$refs.form.$v.$invalid) return

            const { data } = await contractsService.isCodeUnique(this.contract)
            if(data === true) {
                let contract = await this.addContract(this.contract)
                router.push({ path: `/contracts/${contract.id}` })
            } else {                
                this.$toast.add({severity:'warn', summary: 'Šifra zauzeta', detail:'Šifra polaznika je zauzeta.', life: 3000});
            }
        },
        ...mapActions({
            addContract: 'ADDCONTRACT',
        }),
    },
}
</script>