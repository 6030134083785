import httpClient from "./http.service"
import { format } from 'date-fns'

const contractsService = {
    get(params) {
        return httpClient.get(`contracts?page=${params.params.page}`, {
            params: {
                params: params,
            }
        })
    },
    getArchived(params) {
        return httpClient.get(`contract/archived?page=${params.params.page}`, {
            params: {
                params: params,
            }
        })
    },
    getById(contractId) {
        return httpClient.get(`contracts/${contractId}`)
    },
    getSchools() {
        return httpClient.get('contract/get-schools')
    },
    create(contract) {
        contract.date = format(contract.date, 'yyyy-MM-dd 00:00:00')
        return httpClient.post('contracts', contract)
    },
    update(contract) {
        contract.date = format(contract.date, 'yyyy-MM-dd 00:00:00')
        contract.price = Number(contract.price)
        return httpClient.put(`contracts/${contract.id}`, contract)
    },
    archive(contract) {
        return httpClient.put(`contract/archive?contractId=${contract.id}`)
    },
    delete(contractId) {
        return httpClient.delete(`contracts/${contractId}`)
    },
    isCodeUnique(contract) {
        return httpClient.post(`contract/is-code-unique`, contract)
    }
}

export default contractsService;