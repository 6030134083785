<template>
    <div class="navigation p-d-flex p-ai-center p-jc-between p-p-3">
        <svg viewBox="0 0 607.9 512" xmlns="http://www.w3.org/2000/svg">
            <path d="m401.81 0h206.09l-55.222 206.09h-206.09z" fill="#f6ec13"/>
            <path d="m156.65 375.89h158.55l42.484-158.55h-158.55z" fill="#f6ec13"/>
            <path d="m92.877 102.26h126.55l-33.908 126.54h-126.54z" fill="#f6ec13"/>
            <path d="m21.177 41.053h79.029l-21.177 79.029h-79.029z" fill="#f6ec13"/>
            <path d="m8.694 512h120.76l32.358-120.76h-120.76z" fill="#f6ec13"/>
        </svg>

        <div class="p-d-flex p-ai-center">
            {{ user.username }}
            <i class="pi pi-ellipsis-v p-ml-2" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu"></i>
            <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
        </div>
    </div>
</template>

<script>
import authService from '@/services/auth.service'
export default {
    name: 'Nav',
    props: {
        user: Object,
    },
    data() {
        return {
            items: [
                {
                    label: 'Odjava',
                    icon: 'pi pi-sign-out',
                    command: () => {
                        this.logout()
                    }
                },
            ]
        }
    },
    methods: {
        logout() {
            authService.logout()
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
        },
    }
}
</script>