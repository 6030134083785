import httpClient from "./http.service";
import { format } from 'date-fns'

const paymentsService = {
    get() {
        return httpClient.get('payments')
    },
    create(payment) {
        payment.date = format(payment.date, 'yyyy-MM-dd 00:00:00')
        return httpClient.post('payments', payment)
    },
    update(payment) {
        return httpClient.put(`payments/${payment.id}`, payment)
    },
    delete(paymentId) {
        return httpClient.delete(`payments/${paymentId}`)
    }
}

export default paymentsService;