export const Action = {
    GETPAGINATEDCONTRACTS: "GETPAGINATEDCONTRACTS",
    ADDCONTRACT: "ADDCONTRACT",
    GETCONTRACT: "GETCONTRACT",
    UPDATECONTRACT: "UPDATECONTRACT",
    DELETECONTRACT: "DELETECONTRACT",
    ADDPAYMENT: "ADDPAYMENT",
    DELETEPAYMENT: "DELETEPAYMENT",
    ADDCOMMENT: "ADDCOMMENT",
    GETSCHOOLS: "GETSCHOOLS",
    GETCONTRACTS: "GETCONTRACTS",
    ARCHIVECONTRACT: "ARCHIVECONTRACT"
    // SETLOADER: "SETLOADER",
    // SETMODE: "SETMODE",
    // SETACTIVECONTRACT: "SETACTIVECONTRACT",
    // UNSETACTIVECONTRACT: "UNSETACTIVECONTRACT",
    // SHOWFORM: "SHOWFORM",
    // SETTOTALCONTRACTS: "SETTOTALCONTRACTS",
    // SETUSER: "SETUSER",
    // SETERRORS: "SETERRORS",
};

export const Mutation = {
    SETCONTRACTS: "SETCONTRACTS",
    SETCONTRACTCOUNT: "SETCONTRACTCOUNT",
    SETCONTRACTMETA: "SETCONTRACTMETA",
    ADDCONTRACT: "ADDCONTRACT",
    DELETECONTRACT: "DELETECONTRACT",
    REMOVEPAYMENT: "REMOVEPAYMENT",
    ADDCOMMENT: "ADDCOMMENT",
    SETSCHOOLS: "SETSCHOOLS"
    // UPDATECONTRACT: "UPDATECONTRACT",
    // SETLOADER: "SETLOADER",
    // SETMODE: "SETMODE",
    // SETACTIVECONTRACT: "SETACTIVECONTRACT",
    // SHOWFORM: "SHOWFORM",
    // ADDPAYMENTTOACTIVECONTRACT: "ADDPAYMENTTOACTIVECONTRACT",
    // ADDPAYMENTTOCONTRACT: "ADDPAYMENTTOCONTRACT",
    // UNSETACTIVECONTRACT: "UNSETACTIVECONTRACT",
    // SETUSER: "SETUSER",
    // SETERRORS: "SETERRORS",
};