<template>
    <div class="filter p-d-flex p-ai-center">
        <SelectButton id="school" v-model="filter.schoolId" :options="options" optionLabel="name" optionValue="id" class="p-mr-2 schools" />
        <InputText style="width:200px;" type="text" v-model.trim="filter.all" @keydown.enter="$emit('search', filter)" class="p-mr-2 p-inputtext-sm" placeholder="Ime polaznika, šifra, telefon..." />

        <div class="p-buttonset">
            <Button label="Pretraži" icon="pi pi-search" class="p-button-sm" @click="$emit('search', filter)" />
            <Button label="Poništi" icon="pi pi-times-circle" class="p-button-secondary p-button-sm" @click="$emit('reset')" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        filter: {
            type: Object,
            required: true,
        }
    },    
    computed: {
        ...mapGetters({
            schools: 'schools'
        }),
        options: function() {
            let none = { name: 'Sve', id: null }
            return [ none, ...this.schools ]
        }
    }, 
}
</script>

<style>
.p-selectbutton.p-buttonset.schools .p-button {
    font-size:11px !important;
}
</style>