<template>
    <div>
        <Header>
            Korisnički računi
        </Header>

        <div class="actions p-d-flex p-jc-between p-p-2">
            <Button icon="pi pi-plus" label="Dodaj novi korisnički račun" class="p-button-sm" @click="addNew" />
        </div>

        <DataTable
            :value="items.items"
            :loading="loading"
            :rows="items._meta.perPage"
            :totalRecords="items._meta.totalCount"
            ref="users"
            class="p-datatable-sm">
            <template #empty>
                <div class="p-p-3">
                    Nije pronađen nijedan korisnički račun.
                </div>
            </template>
            <Column field="name" header="Ime i prezime"></Column>
            <Column field="username" header="Korisničko ime"></Column>
            <Column header="Škola">
                <template #body="slotProps">
                    {{ schools.find(x => x.id == slotProps.data.schoolId).name }}
                </template>
            </Column>
            <Column header="Status">            
                <template #body="slotProps">
                    {{ slotProps.data.status === 1 ? 'Aktivan' : 'Neaktivan' }}
                </template>
            </Column>
            <Column headerStyle="width: 12em" bodyStyle="text-align: center">
                <template #body="slotProps">
                    <Button icon="pi pi-lock" class="p-button-secondary p-button-text" @click="changePasswordShowDialog(slotProps.data)" />
                    <Button icon="pi pi-pencil" class="p-button-secondary p-button-text" @click="editUser(slotProps.data)" />
                    <Button icon="pi pi-trash" class="p-button-secondary p-button-text" @click="deleteUser(slotProps.data)" />
                </template>
            </Column>
        </DataTable>

        <Dialog :visible.sync="userDialog" :style="{width: '450px'}" header="Korisnički račun" :modal="true" class="p-fluid" :contentStyle="{overflow: 'visible'}">
            
            <div v-if="errors" class="errors">
                <label>Errors</label>
                <p v-for="(error, index) in errors" :key="index">
                    {{ error[0] }}
                </p>
            </div>

            <div class="p-field" :class="{ 'form-group--error': $v.user.schoolId.$error }">
                <label for="totalPrice">Škola</label>
                <SelectButton id="school" v-model="user.schoolId" :options="schools" optionLabel="name" optionValue="id" />
                <small class="p-invalid" v-if="!$v.user.schoolId.required">Polje je obavezno</small>
            </div>              

            <div class="p-field" :class="{ 'form-group--error': $v.user.username.$error }">
                <label for="username">Korisničko ime</label>
                <InputText id="username" type="text" v-model.trim="user.username" :disabled="edit" />
                <small class="p-invalid" v-if="!$v.user.username.required">Polje je obavezno</small>
            </div>  

            <div class="p-field" :class="{ 'form-group--error': $v.user.name.$error }">
                <label for="name">Ime i prezime</label>
                <InputText id="name" type="text" v-model.trim="user.name" />
                <small class="p-invalid" v-if="!$v.user.name.required">Polje je obavezno</small>
            </div>  

            <div class="p-field" :class="{ 'form-group--error': $v.user.password.$error }" v-if="!edit">
                <label for="password">Lozinka</label>
                <Password id="password" v-model.trim="user.password" toggleMask :feedback="false" />
                <small class="p-invalid" v-if="!$v.user.password.required">Polje je obavezno</small>
            </div>

            <div class="p-field" :class="{ 'form-group--error': $v.user.passwordRepeat.$error }" v-if="!edit">
                <label for="passwordRepeat">Ponovi lozinku</label>
                <Password id="passwordRepeat" v-model.trim="user.passwordRepeat" toggleMask :feedback="false" />
                <small class="p-invalid" v-if="!$v.user.passwordRepeat.required">Polje je obavezno</small>
                <small class="p-invalid" v-if="user.passwordRepeat && !$v.user.passwordRepeat.sameAsPassword">Polja lozinka i ponovi lozinku moraju se podudarati</small>
            </div>

            <div class="p-field" :class="{ 'form-group--error': $v.user.status.$error }">
                <label for="status">Status</label>
                <SelectButton id="status" v-model="user.status" :options="status" optionLabel="name" optionValue="value" />
                <small class="p-invalid" v-if="!$v.user.status.required">Polje je obavezno</small>
            </div>

            <template #footer>
                <Button label="Odustani" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                <Button label="Spremi" icon="pi pi-check" @click="saveUser" />
            </template>
        </Dialog>

        <Dialog :visible.sync="changePasswordDialog" :style="{width: '450px'}" header="Izmjeni lozinku" :modal="true" class="p-fluid" :contentStyle="{overflow: 'visible'}">
            
             <div class="p-field" :class="{ 'form-group--error': $v.userPassword.password.$error }">
                <label for="userPassword">Lozinka</label>
                <Password id="userPassword" v-model.trim="userPassword.password" toggleMask :feedback="false" />
                <small class="p-invalid" v-if="!$v.userPassword.password.required">Polje je obavezno</small>
            </div>

            <div class="p-field" :class="{ 'form-group--error': $v.userPassword.passwordRepeat.$error }">
                <label for="passwordRepeat">Ponovi lozinku</label>
                <Password id="passwordRepeat" v-model.trim="userPassword.passwordRepeat" toggleMask :feedback="false" />
                <small class="p-invalid" v-if="!$v.userPassword.passwordRepeat.required">Polje je obavezno</small>
                <small class="p-invalid" v-if="userPassword.passwordRepeat && !$v.userPassword.passwordRepeat.sameAsPassword">Polja lozinka i ponovi lozinku moraju se podudarati</small>
            </div>

            <template #footer>
                <Button label="Odustani" icon="pi pi-times" class="p-button-text" @click="hideChangePasswordDialog"/>
                <Button label="Spremi" icon="pi pi-check" @click="saveChangePassword" />
            </template>
        </Dialog>
        
        <ConfirmDialog></ConfirmDialog>
    </div>
</template>

<script>
import Header from '@/components/common/Header'
import administrationService from '../../services/administration.service'
import { mapActions, mapGetters } from 'vuex'
import { required, requiredIf, sameAs } from 'vuelidate/lib/validators'
export default {
    components: {
        Header,
    },        
    data() {
        return {
            items: {
                _meta: {
                    perPage: 20
                }
            },
            userDialog: false,
            changePasswordDialog: false,
            loading: true,
            edit: false,
            user: {},
            userPassword: {},
            errors: null,
            changePasswordUserId: null,
            status: [
                { name: 'Aktivan', value: 1 },
                { name: 'Neaktivan', value: 0 }
            ]
        }
    },
    async created() {
        await this.getSchools()
        await this.getUsers()
    },     
    computed: {
        ...mapGetters({
            schools: 'schools'
        })
    },        
    validations: {
        user: {
            schoolId: { required },
            password: { 
                required: requiredIf(function() {
                    return !this.edit
                })
            },
            passwordRepeat: { 
                required: requiredIf(function() {
                    return !this.edit
                }),
                sameAsPassword: sameAs('password') 
            },
            name: { required },
            username: { required },
            status: { required }
        },
        userPassword: {
            password: { required },
            passwordRepeat: { required, sameAsPassword: sameAs('password')},
        } 
    },   
    methods: {
        ...mapActions({
            getSchools: 'GETSCHOOLS',
        }),
        async getUsers() {
            this.loading = true
            const { data } = await administrationService.get()
            this.items = data
            this.loading = false
        },
        addNew() {
            this.user = {}
            this.userDialog = true
            this.edit = false
        },        
        hideDialog() {
            this.userDialog = false
            this.user = {}
            this.edit = false
            this.$v.user.$reset()
        },
        hideChangePasswordDialog() {
            this.changePasswordDialog = false
            this.userPassword = {}
            this.changePasswordUserId = null
            this.$v.userPassword.$reset()
        },
        editUser(user) {
            this.user = { ...user }
            this.userDialog = true
            this.edit = true
        },
        deleteUser(user) {
            this.$confirm.require({
                message: 'Sigurno želite obrisati korisnički račun?',
                header: 'Brisanje korisničkog računa',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    this.loading = true
                    await administrationService.delete(user.id)
                    await this.getUsers()
                    this.loading = false
                },
            })
        },
        async save() {
            try {
                const { status, data } = await administrationService.create(this.user)
                if(status === 200) {
                    return { 
                        success: true,
                        data: data
                    }
                }
            } catch(e) {
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async update() {
            try {
                const { status, data } = await administrationService.update(this.user)
                if(status === 200) {
                    return { 
                        success: true,
                        data: data
                    }
                }
            } catch(e) {
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async saveUser() {
            this.$v.user.$touch()
            if (this.$v.user.$invalid) return

            if(this.user.id) {
                let response = await this.update();
                if(response.success) {
                    await this.getUsers()
                    this.userDialog = false
                    this.user = {}
                    this.$v.user.$reset()
                    this.errors = null
                } else {
                    this.errors = response.errors
                }
            } else {
                let response = await this.save();
                if(response.success) {
                    this.items.items.push(response.data)
                    this.userDialog = false
                    this.user = {}
                    this.$v.user.$reset()
                    this.errors = null
                } else {
                    this.errors = response.errors
                }
            }
        },
        async saveChangePassword() {
            this.$v.userPassword.$touch()
            if (this.$v.userPassword.$invalid) return

            this.userPassword.userId = this.changePasswordUserId
            const { data } = await administrationService.changePassword(this.userPassword) 

            if(data.username) {
                this.$toast.add({severity:'success', summary: 'Lozinka ažurirana', detail: `Lozinka je uspješno ažurirana korisniku/ci ${data.name}`, life: 3000});
            } else {
                this.$toast.add({severity:'error', summary: 'Došlo je do pogreške', detail: `Došlo je do pogreške. Molim javite se administratoru`, life: 3000});
            }

            this.hideChangePasswordDialog()
        },
        changePasswordShowDialog(user) {
            this.userPassword = {}
            this.changePasswordUserId = user.id
            this.changePasswordDialog = true
        }
    }
}
</script>