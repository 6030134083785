<template>
    <div class="relative">
        <Header>
            Arhiva
        </Header>

        <div class="actions p-d-flex p-jc-between p-p-2">
            <ContractFilter :filter="filter" @search="filterSearch" @reset="reset" />
        </div>

        <DataTable
            scrollHeight="flex" 
            :value="items.items"     
            :lazy="true"       
            :rows="items._meta.perPage"
            :paginator="true"
            :autoLayout="true"
            :totalRecords="items._meta.totalCount"
            :loading="loading"
            class="p-datatable-sm"
            ref="archive"            
            selectionMode="single"
            dataKey="id"
            @page="onPage($event)"
            @row-select="onRowSelect"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="Prikaz {first} do {last} od {totalRecords} ugovora">
            <template #empty>
                <div class="p-p-3">
                    Nije pronađen nijedan ugovor.
                </div>
            </template>
            <Column>
                <template #body="slotProps">
                    <i class="pi pi-exclamation-triangle p-text-gray" :class="{ 'p-text-warning': slotProps.data.warning }"></i>
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <div class="p-d-flex p-ai-center">
                        <i class="pi pi-comments p-mr-2"></i>
                        {{ slotProps.data.comments.length }}
                    </div>
                </template>
            </Column>
            <Column header="Škola">
                <template #body="slotProps">
                    {{ schools.find(x => x.id == slotProps.data.schoolId).name }}
                </template>
            </Column>
            <Column field="date" header="Datum upisa"></Column>
            <Column field="name" header="Ime polaznika"></Column>
            <Column field="code" header="Šifra"></Column>
            <Column field="telephone" header="Telefon"></Column>
            <Column field="category" header="Kategorija"></Column>
            <Column header="Cijena">
                <template #body="slotProps">
                    {{ slotProps.data.price }} HRK
                </template>
            </Column>
            <Column header="# uplata">
                <template #body="slotProps">
                    <div class="p-d-flex p-ai-center">
                        <i class="pi pi-credit-card p-mr-2"></i>
                        {{ slotProps.data.payments.length }}
                    </div>
                </template>
            </Column>
            <Column header="Uplaćeno">
                <template #body="slotProps">
                    <div class="p-d-flex p-ai-center">
                        <ProgressBar :showValue="false" :value="parseFloat(slotProps.data.percentPaid)" style="height: .2em; width:80px;" class="p-mr-3" />
                        {{ slotProps.data.paymentSum }} HRK ({{ slotProps.data.percentPaid }}%)
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import ContractFilter from '@/components/contracts/Filter'
import Header from '@/components/common/Header'
import contractService from '../../services/contracts.service'
import contractHelper from '../../services/contracts.helper'
import { mapGetters, mapActions } from 'vuex'
import router from '@/router'
export default {
    components: {
        Header,
        ContractFilter
    },    
    data() {
        return {
            items: {
                _meta: {
                    perPage: 20
                }
            },
            loading: true,
            filter: {},
            params: {
                first: 0,
                page: 1,
                rows: 20,                
                sortField: null,
                sortOrder: null,
                filters: null
            }
        }
    },
    computed: {
        ...mapGetters({
            schools: 'schools'
        })
    }, 
    async created() {        
        await this.getSchools()
        await this.archived()
    },    
    methods: {
        ...mapActions({
            getSchools: 'GETSCHOOLS',
        }),
        onRowSelect(event) {
            router.push({ path: `/archive/${event.data.id}` })
        },
        async archived() {
            this.loading = true
            const { data } = await contractService.getArchived({ params: this.params, filter: this.filter })
            data.items.map(x => contractHelper.CalculatePercentPaid(x))
            this.items = data
            this.loading = false
        },
        async filterSearch() {
            await this.archived()
        },
        async reset() {
            this.filter = {}
            await this.archived()
        },
        async onPage(event) {
            this.params = event
            this.params.page = event.page + 1
            await this.archived()
        },
    }
}
</script>