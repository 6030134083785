<template>
    <div class="contract--layout p-d-flex p-flex-column">
        <div class="actions p-d-flex p-jc-between p-p-2">
            <span class="p-d-flex p-ai-center">
                <Button label="Natrag" icon="pi pi-arrow-left" class="p-button-text p-button-sm" @click="back()" />
            </span>
            <Button v-if="!edit" label="Uredi" icon="pi pi-pencil" class="p-button-sm" @click="editContract()" />
            <div v-else class="p-buttonset">
                <Button label="Spremi" icon="pi pi-check" class="p-button-sm" @click="update()" />
                <Button label="Odustani" icon="pi pi-times" class="p-button-text p-button-sm" @click="cancel()" />
            </div>
        </div>

        <div class="contract--data p-d-flex" v-if="contract">
            <div class="contract--form">                
                <Header>
                    <span v-if="contract">{{ contract.name }} ({{ contract.code }})</span>
                </Header>
                <Form :contract.sync="contract" :edit.sync="edit" mode="edit" ref="form" />
            </div>
            <div class="p-d-flex flex-grow">
                <div class="contract--payments flex-grow">
                    <Payments v-show="!edit" :contract="contract" @add-payment="addPayment" @remove-payment="removePayment" />
                </div>
                <div class="contract--comments">
                    <Comments v-show="!edit" :contract="contract" @add-comment="addComment" /> 
                </div>
            </div>
        </div>

        <!-- <TabView v-if="contract">
            <TabPanel header="Podaci">                
                <div class="p-p-5">
                    <Form :contract.sync="contract" :edit.sync="edit" mode="edit" ref="form" />
                </div>                
            </TabPanel>
            <TabPanel :header='paymentTitle' :disabled="edit">
                <Payments v-show="!edit" :contract="contract" @add-payment="addPayment" @remove-payment="removePayment" />
            </TabPanel>
            <TabPanel :header='commentTitle' :disabled="edit">
                <Comments v-show="!edit" :contract="contract" @add-comment="addComment" /> 
            </TabPanel>
        </TabView> -->
    </div>
</template>

<script>
import contractsHelper from '../../services/contracts.helper'
import Header from '@/components/common/Header'
import Form from './Form'
import Payments from './payments/Payments'
import Comments from './comments/Comments'
import router from '@/router'
import { mapActions } from 'vuex'
import { parse } from 'date-fns'
import contractsService from '../../services/contracts.service'
export default {
    props: {
        id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            edit: false,
            contract: null,
            loading: true,
            unchangedContract: {}
        }
    },
    components: {
        Header,
        Form,
        Payments,
        Comments
    },
    async created() {
        this.contract = await this.getContract(this.id)
        this.contract.date = parse(this.contract.date, "yyyy-MM-dd", new Date())
        this.unchangedContract = { ...this.contract }
        this.loading = false
    },
    computed: {
        paymentTitle: function() {
            return `Uplate ${this.contract.payments.length}`
        },
        commentTitle: function() {
            return `Komentari ${this.contract.comments.length}`
        }
    },
    methods: {
        ...mapActions({
            getContract: 'GETCONTRACT',
            updateContract: 'UPDATECONTRACT',
        }),        
        back() {
            router.push({ path: '/contracts' })
        },
        editContract() {
            this.edit = true
        },        
        cancel() {
            this.edit = false
            this.contract = { ...this.unchangedContract }
        },
        async update() {            
            this.$refs.form.$v.$touch()
            if (this.$refs.form.$v.$invalid) return

            const { data } = await contractsService.isCodeUnique(this.contract)
            if(data === true) {
                this.contract = await this.updateContract(this.contract)            
                this.contract.date = parse(this.contract.date, "yyyy-MM-dd", new Date())
                this.edit = false
            } else {                
                this.$toast.add({severity:'warn', summary: 'Šifra zauzeta', detail:'Šifra polaznika je zauzeta.', life: 3000});
            }
        },
        addPayment(payment) {
            this.contract.payments.push(payment)
            contractsHelper.CalculatePercentPaid(this.contract)
        },
        removePayment(payment) {
            this.contract.payments = this.contract.payments.filter(x => x.id !== payment.id)
            contractsHelper.CalculatePercentPaid(this.contract)
        },
        addComment(comment) {
            this.contract.comments.push(comment)
        },
    },
}
</script>