<template>
    <div class="relative">
        <ConfirmDialog></ConfirmDialog>

        <Header>
            Ugovori
        </Header>

        <div class="actions p-d-flex p-jc-between p-p-2">
            <ContractFilter :filter="filter" @search="filterSearch" @reset="reset" />
            <router-link to='/contracts/add'>
                <Button icon="pi pi-plus" label="Dodaj novi ugovor" class="p-button-sm" />
            </router-link>
        </div>
        
        <DataTable
            scrollHeight="flex" 
            :value="contracts"
            :lazy="true"
            :rows="params.rows"
            :paginator="true"
            :autoLayout="true"
            ref="contracts"
            :totalRecords="contractMeta.totalCount"
            :loading="loading"
            class="p-datatable-sm"
            selectionMode="single"
            dataKey="id"
            @page="onPage($event)"
            @sort="onSort($event)"
            @row-select="onRowSelect"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="Prikaz {first} do {last} od {totalRecords} ugovora"
            @row-unselect="onRowUnselect">
            <template #empty>
                <div class="p-p-3">
                    Nije pronađen nijedan ugovor.
                </div>
            </template>
            <!-- <Column>
                <template #body="slotProps">
                    <i class="pi pi-exclamation-triangle p-text-gray" :class="{ 'p-text-warning': slotProps.data.warning }"></i>
                </template>
            </Column> -->
            <Column>
                <template #body="slotProps">
                    <div class="p-d-flex p-ai-center">
                        <i class="pi pi-comments p-mr-2 p-text-gray"></i>
                        {{ slotProps.data.comments.length }}
                    </div>
                </template>
            </Column>
            <Column header="Škola">
                <template #body="slotProps">
                    {{ schools.find(x => x.id == slotProps.data.schoolId).name }}
                </template>
            </Column>
            <Column header="Datum">
                <template #body="slotProps">
                    {{ format(parse(slotProps.data.date, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy') }}
                </template>
            </Column>
            <Column field="name" header="Ime polaznika"></Column>
            <Column field="code" header="Šifra"></Column>
            <Column field="telephone" header="Telefon"></Column>
            <Column field="category" header="Kategorija"></Column>
            <Column header="Cijena">
                <template #body="slotProps">
                    {{ slotProps.data.price }} HRK
                </template>
            </Column>
            <Column header="# uplata">
                <template #body="slotProps">
                    <div class="p-d-flex p-ai-center">
                        <i class="pi pi-credit-card p-mr-2 p-text-gray"></i>
                        {{ slotProps.data.payments.length }}
                    </div>
                </template>
            </Column>
            <Column header="Uplaćeno">
                <template #body="slotProps">
                    <div class="p-d-flex p-ai-center">
                        <ProgressBar :showValue="false" :value="parseFloat(slotProps.data.percentPaid)" style="height: .2em; width:80px;" class="p-mr-3" />
                        {{ slotProps.data.paymentSum }} HRK ({{ slotProps.data.percentPaid }}%)
                    </div>
                </template>
            </Column>
            <Column headerStyle="width: 8em" bodyStyle="text-align: center">
                <template #body="slotProps">
                    <Button icon="pi pi-book" class="p-button-secondary p-button-text" @click="archive(slotProps.data)" :disabled="!canArchive(slotProps.data)" />
                    <Button icon="pi pi-trash" class="p-button-secondary p-button-text" @click="deleteItem(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import ContractFilter from '@/components/contracts/Filter'
import Header from '@/components/common/Header'
import { parse, format } from 'date-fns'
import router from '@/router'
import { mapActions, mapGetters } from 'vuex'
export default {   
    components: {
        Header,
        ContractFilter
    },
    data() {
        return {
            loading: true,
            filter: {},
            params: {
                first: 0,
                page: 1,
                rows: 20,                
                sortField: null,
                sortOrder: null,
                filters: null
            }
        }
    },
    computed: {
        ...mapGetters({
            contracts: 'contracts',
            contractMeta: 'contractMeta',
            schools: 'schools'
        })
    }, 
    async created() {                
        await this.getSchools()
        await this.search()
    },
    methods: {
        parse: parse,
        format: format,
        canArchive(contract) {
            return contract.paymentSum >= contract.price
        },
        ...mapActions({
            getContracts: 'GETCONTRACTS',            
            archiveContract: 'ARCHIVECONTRACT',
            getSchools: 'GETSCHOOLS',
            getPaginatedContracts: 'GETPAGINATEDCONTRACTS',
            deleteContract: "DELETECONTRACT"
        }),
        onRowSelect(event) {
            router.push({ path: `/contracts/${event.data.id}` })
        },
        onRowUnselect(event) {
            console.log(event)
        },
        async deleteItem(contract) {          
            this.$confirm.require({
                message: 'Sigurno želite obrisati ugovor?',
                header: 'Brisanje ugovora',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    this.loading = true
                    await this.deleteContract(contract)
                    await this.getContracts({ params: this.params, filter: this.filter })
                    this.loading = false
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            })
        },        
        async archive(contract) {          
            this.$confirm.require({
                message: 'Sigurno želite arhivirati ugovor?',
                header: 'Arhiviranje ugovora',
                icon: 'pi pi-book',
                accept: async () => {
                    this.loading = true
                    contract.archived = 1
                    await this.archiveContract(contract)   
                    await this.getContracts({ params: this.params, filter: this.filter })
                    this.loading = false
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            })
        },
        async filterSearch() {
            await this.search()
        },
        async reset() {
            this.filter = {}
            await this.search()
        },
        async search() {
            this.loading = true
            await this.getContracts({ params: this.params, filter: this.filter })
            this.loading = false
        },
        async onPage(event) {
            this.params = event
            this.params.page = event.page + 1
            await this.search()
        },
    },
}
</script>