<template>
    <div class="payments-inner">
        <Header>Uplate</Header>

        <div class="p-d-flex p-jc-between p-p-2" v-if="mode != 'details'">
            <Button icon="pi pi-plus" label="Nova uplata" @click="showPaymentForm" class="p-button-sm" />
        </div>
        <ProgressBar :showValue="false" :value="parseFloat(contract.percentPaid)" style="height: .3em; width:100%" class="p-mr-3" />

        <div class="payment--details p-d-flex p-p-2 p-ai-center p-jc-between">
            <div class="p-px-4 p-py-3 p-mr-2 p-d-flex p-flex-column">
                <label>Broj uplata</label>
                <p class="p-mb-0 p-text-bold p-mt-2">#{{ contract.payments.length }}</p>
            </div>
            <div class="p-px-4 p-py-3 p-mr-2 p-d-flex p-flex-column">
                <label>Ukupno uplaćeno</label>
                <p class="p-mb-0 p-text-bold p-mt-2">{{ contract.paymentSum }} / {{ contract.price }} HRK</p>
            </div>
            <div class="p-px-4 p-py-3 p-d-flex p-flex-column">
                <label>Za uplatiti</label>
                <p class="p-mb-0 p-text-bold p-mt-2">{{ contract.price - contract.paymentSum }} HRK</p>
            </div>            
            <div class="p-px-4 p-d-flex p-flex-column">
                <Knob v-model="contract.percentPaid" readonly valueTemplate="{value}%" :size="50" valueColor="#20c997" />
            </div>
        </div>

        <div class="p-d-flex p-flex-column">
            <DataTable v-if="contract.payments.length" :value="contract.payments" class="p-datatable-sm">
                <Column header="Datum">
                    <template #body="slotProps">
                        {{ format(parse(slotProps.data.date, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy') }}
                    </template>
                </Column>          
                <Column header="Iznos">
                    <template #body="slotProps">
                        {{ slotProps.data.amount }} HRK
                    </template>
                </Column>
                <Column field="type" header="Tip uplate"></Column>  
                <Column field="note" header="Napomena"></Column>  
                <Column headerStyle="width: 6em" bodyStyle="text-align: center" v-if="mode != 'details'">
                    <template #body="slotProps">
                        <Button icon="pi pi-trash" class="p-button-danger p-button-text" @click="removePayment(slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
        </div>

        <Dialog :visible.sync="paymentDialog" header="Nova uplata" :style="{width: '30vw'}" :modal="true" class="p-fluid" :contentStyle="{overflow: 'visible'}">
            <Form :contract="contract" :payment="payment" ref="form" @max-payment="setMaxPayment" /> 
            <template #footer>
                <Button label="Odustani" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                <Button label="Spremi" icon="pi pi-check" @click="savePayment" />
            </template>           
        </Dialog>
        
        <ConfirmDialog></ConfirmDialog>
    </div>
</template>

<script>
import Header from '@/components/common/Header'
import { parse, format } from 'date-fns'
import { mapActions } from 'vuex'
import Form from './Form'
export default {
    props: {
        contract: {
            type: Object,
            required: true,
        },
        mode: {
            type: String,
            default: null,
        }
    },
    data() {
        return {            
            payment: {},
            paymentDialog: false,
        }
    },
    components: {
        Header,
        Form,
    },
    methods: {
        parse: parse,
        format: format,
        ...mapActions({
            deletePayment: 'DELETEPAYMENT',
            addPayment: 'ADDPAYMENT'
        }),
        showPaymentForm() {            
            this.payment = {}
            this.paymentDialog = true
            // this.$emit('add-payment', payment)
        },
        async removePayment(payment) {
            this.$confirm.require({
                message: 'Sigurno želite obrisati uplatu?',
                header: 'Brisanje uplate',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    await this.deletePayment(payment)
                    this.$emit('remove-payment', payment)
                }
            })
        },      
        hideDialog() {
            this.paymentDialog = false
            this.payment = {}
            // this.$v.$reset()
        },          
        async savePayment() {
            this.$refs.form.$v.$touch()
            if (this.$refs.form.$v.$invalid) return

            if(this.payment.amount === 0)
                return

            this.payment.contractId = this.contract.id

            if(this.contract.paymentSum + this.payment.amount > this.contract.price) {
                this.$confirm.require({
                    message: 'Ukoliko dodate uplatu suma uplata biti će veća od dogovorenog iznosa? Želite li svejedno dodati uplatu?',
                    header: 'Upozorenje',
                    icon: 'pi pi-exclamation-triangle',
                    accept: async () => {
                        await this.saveAndHideDialog()
                    },
                })
            } else {
                await this.saveAndHideDialog()
            }
        },
        async saveAndHideDialog() {
            let payment = await this.addPayment(this.payment)
            this.$emit('add-payment', payment)
            this.hideDialog()
            this.$refs.form.$v.$reset()
        },
        setMaxPayment() {
            var maxPaymentAmount = this.contract.price - this.contract.paymentSum
            if(maxPaymentAmount > 0) 
                this.$set(this.payment, 'amount', maxPaymentAmount)
        }
    }
}
</script>