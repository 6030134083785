import Axios from 'axios'
import router from '../router'
import authService from './auth.service'

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT || 'http://localhost/croatiastart-contracts/public_html/api.croatiastart.hr/'

let config = {
    baseURL: `${API_ENDPOINT}api/`
}

const httpClient = Axios.create(config)

const authInterceptor = config => {
    config.headers.Authorization = `Bearer ${authService.getToken()}`
    config.headers.Pragma = 'no-cache'
    config.headers.Expires = '0'
    return config
}

httpClient.interceptors.request.use(authInterceptor)

httpClient.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if(error.response.status === 401) {
            router.push({ name: 'login' })
        }
        return Promise.reject(error)
    }
)

export default httpClient