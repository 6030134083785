import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import contracts from './contracts'
import auth from './auth'

const store = new Vuex.Store({    
    modules: {
        auth,
        contracts,
    },
})

export default store