<template>
    <div class="p-fluid">
        <div class="p-field" :class="{ 'form-group--error': $v.payment.date.$error }">
            <label for="date">Datum uplate</label>
            <Calendar v-model="payment.date" :numberOfMonths="2" dateFormat="dd.mm.yy" :showButtonBar="true" placeholder="Datum" />
            <small class="p-invalid" v-if="!$v.payment.date.required">Field is required.</small>
        </div>

        <div class="p-field" :class="{ 'form-group--error': $v.payment.amount.$error }">
            <label for="amount">Iznos uplate</label>
            <div class="p-inputgroup">
                <InputNumber id="amount" showButtons v-model="payment.amount" mode="currency" currency="HRK" locale="hr-HR" placeholder="Iznos" />
                <Button icon="pi pi-caret-up" @click="setMaxPayment()" class="p-button-outlined" v-tooltip="'Maksimalan iznos'" />
            </div>

            <!-- <div class="p-d-flex">
                <InputNumber id="amount" class="p-mr-2" showButtons v-model="payment.amount" mode="currency" currency="HRK" locale="hr-HR" placeholder="Iznos" />
                <Button label="Max." style="width:80px" @click="setMaxPayment()" />
            </div> -->
            <small class="p-invalid" v-if="!$v.payment.amount.required">Field is required.</small>
        </div>
        
        <div class="p-field" :class="{ 'form-group--error': $v.payment.type.$error }">
            <label for="type">Vrsta uplate</label>
            <SelectButton id="type" v-model="payment.type" :options="types" optionLabel="name" optionValue="value" placeholder="Odaberi" />
            <!-- <Dropdown id="type" v-model="payment.type" :options="types" optionLabel="name" optionValue="value" placeholder="Odaberi" /> -->
            <small class="p-invalid" v-if="!$v.payment.type.required">Field is required.</small>
        </div>

        <div class="p-field">
            <label for="note">Napomena</label>            
            <Textarea id="note" v-model.trim="payment.note" rows="4" />
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
    props: {
        contract: {
            type: Object,
            required: true,
        },
        payment: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            types: [
                { name: 'Online', value: 'Online uplata' },
                { name: 'Kartica', value: 'Kartica' },
                { name: 'Gotovina', value: 'Gotovina' },
                { name: 'Ostalo', value: 'Ostalo' }
            ]
        }
    },
    validations: {
        payment: {
            amount: { required },
            date: { required },
            type: { required }
        } 
    },
    methods: {
        setMaxPayment() {
            this.$emit('max-payment')
        },
    }
}
</script>